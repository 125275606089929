import { Observable } from 'rxjs';

import {
  Completion,
  CompletionCreateParams,
  ExecutionTimeParam,
  Prediction,
  QuerySuggestion,
  RecordFeedbackParams,
  Reference,
  UnitNameItem,
  UpdateSuggestionScoreParams,
} from '../models/chat.model';

export abstract class ChatGateway {
  // completion
  abstract createCompletion(params: CompletionCreateParams): Observable<Completion>;
  // prediction
  abstract listPredictionWords(words: string): Observable<Prediction>;
  // record
  abstract updateFeedback(queryId: string, params: RecordFeedbackParams): Observable<{ message: string }>;
  // reference
  abstract getReference(docKey: string, isOriginal?: boolean): Observable<Reference>;
  abstract getUnitNameTable(url: string): Observable<UnitNameItem>;
  // time
  abstract updateExecutionTime(date: number, executionTimeParam: ExecutionTimeParam): Observable<{ message: string }>;
  // suggestion
  abstract getQuerySuggestion(question: string): Observable<QuerySuggestion>;
  // suggestion-score
  abstract updateSuggestionScore(terms: string, params: UpdateSuggestionScoreParams): Observable<{ message: string }>;
}
